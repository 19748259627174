import React, { Component } from 'react';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import StepConnector from '@material-ui/core/StepConnector';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CircleIcon from '@material-ui/icons/FiberManualRecord';
import Link from '@material-ui/core/Link';
import Container from '@material-ui/core/Container';

import settings from '../settings';

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: '2rem',
    paddingBottom: '2rem',
    textAlign: 'center',
    maxWidth: 1200,
    margin: '0 auto',
  },
  header: {
    margin: '0 auto',
    maxWidth: 600,
    textAlign: 'center',
    marginTop: 32,
  },
  showcase: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    [`@media (max-width: ${settings.maxMobileWidth}px)`]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
    marginTop: 64,
  },
  firstTweetContainer: {
    flex: 1,
  },
  secondTweetContainer: {
    flex: 1,
    marginLeft: 30,
    [`@media (max-width: ${settings.maxMobileWidth}px)`]: {
      marginLeft: 0,
    },
  },
  stepperContainer: {
    flex: 1,
  },
  cmd: {
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 10,
    paddingRight: 10,
    backgroundColor: '#fff',
    color: '#ff3d00',
    fontWeight: 'bold',
    borderRadius: 5,
  },
  stepLabel: {
    marginTop: 0,
    fontWeight: 'normal !important',
  },
}));

const Home = () => {
  const classes = useStyles();
  const steps = [
    {
      label: <>We are here to help you with chess diagrams on Twitter!</>,
    },
    {
      label: <>From any tweet with a chess diagram image, <b>mention us</b> with a keyword <b>"scan"</b> and we'll reply back with diagram analysis</>,
      content: <>
        <br />
        <code className={classes.cmd}>@ChessvisionAi scan</code>
      </>,
    },
    {
      label: <>You can also give us a hint whose turn is it by using "scan white" or "scan black"</>,
      content: <>
        <br />
        <code className={classes.cmd}>@ChessvisionAi scan white</code>
        <br />
        <br />
        <code className={classes.cmd}>@ChessvisionAi scan black</code>
      </>
    },
    {
      label: <>Follow <Link href="https://twitter.com/chessvisionai">@ChessvisionAi</Link> to mention us easily!</>,
    },
    {
      label: <><Link href="https://twitter.com/ChessvisionAI/status/1511345631276765188">Practice here</Link> or try our <Link href="https://chessvision.ai">other chess apps</Link></>,
    },
  ];

  return (
    <Container className={classes.container}>
      <div className={classes.header}>
        <Typography variant="h4" style={{ fontWeight: 800 }}>
          We help you analyze chess diagrams on Twitter easily!
        </Typography>
      </div>
      <div className={classes.showcase}>
        <div className={classes.firstTweetContainer}>
          <blockquote class="twitter-tweet" data-theme="dark"><p lang="en" dir="ltr">I&#39;m <a href="https://twitter.com/ChessvisionAI?ref_src=twsrc%5Etfw">@ChessvisionAi</a> a Twitter bot to help you analyze chess diagrams. To trigger me, reply to any tweet with a chess diagram and mention me with the &quot;scan&quot; keyword and I&#39;ll reply back with my analysis. You can also use keywords &quot;scan white&quot; or &quot;scan black&quot; to hint whose turn is it <a href="https://t.co/A7zI3w2ILW">pic.twitter.com/A7zI3w2ILW</a></p>&mdash; Chessvision.ai (@ChessvisionAI) <a href="https://twitter.com/ChessvisionAI/status/1513501973416452099?ref_src=twsrc%5Etfw">April 11, 2022</a></blockquote>
        </div>
        <div className={classes.secondTweetContainer}>
          <blockquote class="twitter-tweet" data-theme="dark"><p lang="en" dir="ltr">Analyze on <a href="https://t.co/lCiKXDJRTS">https://t.co/lCiKXDJRTS</a> or <a href="https://t.co/RZlJgwsPKK">https://t.co/RZlJgwsPKK</a> From game <a href="https://twitter.com/MagnusCarlsen?ref_src=twsrc%5Etfw">@MagnusCarlsen</a> vs. Sergey Karjakin, 2016: <a href="https://t.co/A2wV7Eizpx">https://t.co/A2wV7Eizpx</a> Videos with this position: <a href="https://t.co/fQjqXXW57J">https://t.co/fQjqXXW57J</a> White has mate in 2. Hint: Queen. Continuation: Qh6+ Kxh6 Rh8#</p>&mdash; Chessvision.ai (@ChessvisionAI) <a href="https://twitter.com/ChessvisionAI/status/1513502179394560004?ref_src=twsrc%5Etfw">April 11, 2022</a></blockquote>
        </div>
        <div className={classes.stepperContainer}>
          <Stepper orientation="vertical" style={{ backgroundColor: 'inherit', textAlign: 'left' }}>
            {steps.map(({ label, content }) => (
              <Step key={label} active expanded>
                <StepLabel
                  icon={<CircleIcon/>}
                  style={{ color: '#ff3d00' }}
                  classes={{
                    label: classes.stepLabel,
                  }}
                >{label}</StepLabel>
                { content && (
                  <StepContent>{content}</StepContent>
                )}
              </Step>
            ))}
          </Stepper>
        </div>
      </div>
    </Container>
  );
}

export default Home;
