import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';

import settings from './settings';

const initialPalette = {
  primary: settings.theme.primaryColor,
  secondary: settings.theme.secondaryColor,
  background: settings.theme.backgroundColor,
};

let theme = createTheme({
  // palette: initialPalette,
  typography: {
    h2: {
      [`@media (max-width: ${settings.maxMobileWidth}px)`]: {
        fontSize: '2.75rem',
      },
    },
    body1: {
      [`@media (max-width: ${settings.maxMobileWidth}px)`]: {
        fontSize: '0.9rem',
      },
    },
  },
  palette: {
    primary: {
      main: '#007bff',
    },
    secondary: {
      main: '#d64f00',
    },
    success: {
      main: '#66bb6a',
    },
    background: {
      default: '#161512',
      paper: '#262421',
    },
    gray: {
      main: '#828282',
      dark: '#282828',
    },
    text: {
      primary: '#fff',
      secondary: '#999',
      disabled: '#666',
      icon: '#fff',
    },
    action: {
      active: '#828282',
      disabled: '#666',
      disabledBackground: '#333',
    },
  },
});

theme = responsiveFontSizes(theme);

export default theme;
